import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["unread-badge"]

  unreadBadgeOutletConnected(outlet) {
    console.debug("[Dismiss Toast]", "Dismissed")
    outlet.dismiss()
  }
}
