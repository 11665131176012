import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  timeout = null

  connect() {
    this.element.addEventListener("turbo:morph-element", this.schedule)
    this.schedule()
  }

  disconnect() {
    this.element.removeEventListener("turbo:morph-element", this.schedule)
    this.cancel()
  }

  schedule = (event) => {
    this.cancel()
    this.timeout = setTimeout(() => this.poll(), 2000)
  }

  cancel() {
    clearTimeout(this.timeout)
  }

  poll() {
    Turbo.session.refresh(document.baseURI)
  }
}
