import { Controller } from "@hotwired/stimulus";

export default class MobileMenuController extends Controller {
  select(event) {
    const selectedOption = event.target.selectedOptions[0]
    const { value: url, dataset: { method } } = selectedOption

    if (!method) {
      window.location = url
    } else {
      this.#submit(url, method)
    }
  }

  #submit(url, method) {
    const form = Object.assign(document.createElement("form"), {
      method: "POST",
      action: url,
      style: "display: none"
    })

    // METHOD
    form.appendChild(Object.assign(document.createElement("input"), {
      type: "hidden",
      name: "_method",
      value: method.toUpperCase()
    }))

    // CSRF
    const { param, token } = this.#csrf()

    if (param && token) {
      form.appendChild(Object.assign(document.createElement("input"), {
        type: "hidden",
        name: param,
        value: token
      }))
    }

    // Submit!
    document.body.appendChild(form)
    form.submit()
  }

  #csrf() {
    const param = document.querySelector("meta[name='csrf-param']")?.content
    const token = document.querySelector("meta[name='csrf-token']")?.content
    return { param, token }
  }
}
