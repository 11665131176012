import { Controller } from "@hotwired/stimulus";

/**
 * We can remove this once testing has completed.
 *
 * This is used to check if our source maps are working properly in production. How you use it is:
 * ```javascript
 * const node = document.createElement("div")
 * node.dataset.controller = "debug-honeybadger"
 * document.body.append(node) # => boom, some time later
 * ```
 */
export default class DebugHoneybadgerController extends Controller {
  static version = "v1"

  connect() {
    throw new Error(`This is a test error from DebugHoneybadgerController ${this.constructor.version}`)
  }
}