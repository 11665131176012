import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["placeholder"]

  hide() {
    const element = this.element.querySelector("turbo-frame")
    element?.replaceChildren()
    this.placeholderTarget.classList.remove("hidden")
  }

  show() {
    this.placeholderTarget.classList.add("hidden")
  }
}
