import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "submitter"]

  requestSubmit() {
    if (this.hasSubmitterTarget) {
      this.formTarget.requestSubmit(this.submitterTarget)
    } else {
      this.formTarget.requestSubmit()
    }
  }
}
