import { Controller } from "@hotwired/stimulus"
import { spotMap } from "../spot_map/spot_map"

export default class extends Controller {
  static outlets = ["spot-map"]
  static targets = ["spotMapContainer"]

  #disconnect = undefined

  connect() {
    this.#disconnect = spotMap(this.spotMapContainerTarget)
    console.debug("[React Booking Editor]", "connect()");
    this.spotMapOutlet.show()
  }

  disconnect() {
    this.#disconnect?.()
  }
}
