import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["amount", "percentage"]
  static values = {
    totalAmounts: Object,
    costType: String,
  }

  setAmount(event) {
    if (!this.#totalAmount) return

    this.#amount = this.#amountFromPercentage(Number(event.target.value))
  }

  setPercentage(event) {
    if (!this.#totalAmount) return

    this.#percentage = this.#percentageFromAmount(Number(event.target.value))
  }

  setCostType(event) {
    this.costTypeValue = event.target.value

    if (!this.#totalAmount) return

    if (this.percentageTarget.value) {
      this.#amount = this.#amountFromPercentage(this.#percentage)
    } else if (this.amountTarget.value) {
      this.#percentage = this.#percentageFromAmount(this.#amount)
    }
  }

  #amountFromPercentage(percentage) {
    return ((this.#totalAmount * percentage) / 100).toFixed(2)
  }

  #percentageFromAmount(amount) {
    return ((amount / this.#totalAmount) * 100).toFixed(2)
  }

  set #amount(value) {
    if (value && value >= 0) this.amountTarget.value = value
  }

  get #amount() {
    return Number(this.amountTarget.value)
  }

  set #percentage(value) {
    if (value && value >= 0) this.percentageTarget.value = value
  }

  get #percentage() {
    return Number(this.percentageTarget.value)
  }

  get #totalAmount() {
    return this.totalAmountsValue[this.costTypeValue]
  }
}
