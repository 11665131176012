/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
export default class DockController {
  constructor(map, options) {
    this.map = map;
    if (options == null) { options = {}; }
    this.locale = options.locale;
  }

  draw(dock, marker) {
    this._attachEvents(dock, marker);
    return marker.draw(this.map);
  }

  _attachEvents(dock, marker) {
    return this._attachMarkerClick(dock, marker);
  }

  _attachMarkerClick(dock, marker) {
    return marker.leafletMarker.on("click", () => {
      return (window.location = `/${this.locale}/docks/${dock.param}`);
    });
  }
}
