import { dom } from "./dom"

const invisibleDisableFunction = (event) => event.preventDefault()

const invisibleDisableSelector = "input[data-invisible-disable], button[data-invisible-disable]"
const disableSelector = "input[data-disable-with], button[data-disable-with]"

const setText = (input, text) => {
  if (input.type === "submit") {
    input.value = text
  } else {
    input.textContent = text
  }
}

const disable = function (input) {
  input.disabled = true
  setText(input, input.dataset.disableWith)
}

const invisibleDisable = (input) =>
  input.on("click", invisibleDisableFunction)

const initDisableWith = function () {
  window.addEventListener(
    "pageshow",
    () =>
      dom
        .findAll(invisibleDisableSelector)
        .forEach((input) =>
          input.removeEventListener("click", invisibleDisableFunction)
        ),
    false
  )

  dom.findAll("form").on("submit", function (event) {
    if (!event.defaultPrevented) {
      event.target
        .findAll(invisibleDisableSelector)
        .forEach((input) => invisibleDisable(input))
      event.target
        .findAll(disableSelector)
        .forEach((input) => disable(input, event))
    }
  })
}

export default initDisableWith
