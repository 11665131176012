import { Controller } from "@hotwired/stimulus"
import * as mobiscroll from "@mobiscroll/javascript"

export default class extends Controller {
  static values = {
    locale: String,
    rangeStartLabel: String,
    rangeEndLabel: String,
  }

  static targets = [
    "startDisplay",
    "endDisplay",
    "picker",
    "startValue",
    "endValue",
  ]

  setEndDateAsActiveDate() {
    this.mobiscroll.setOptions({
      onActiveDateChange: (_args) => {
        this.mobiscroll.setActiveDate("end")
      },
    })
  }

  connect() {
    this.mobiscroll = mobiscroll.datepicker(this.pickerTarget, {
      // Style
      theme: "material",
      themeVariant: "light",
      controls: ["calendar"],
      select: "range",
      rangeStartLabel: this.rangeStartLabelValue,
      rangeEndLabel: this.rangeEndLabelValue,
      showWeekNumbers: true,
      startInput: this.startDisplayTarget,
      endInput: this.endDisplayTarget,
      anchor: this.startDisplayTarget,
      touchUi: "auto", // Sets touch UI based on device type
      responsive: {
        xsmall: {
          // min-width: 0px
          display: "bottom",
        },
        small: {
          // min-width: 576px
          display: "bottom",
        },
        medium: {
          // min-width: 768px
          display: "anchored",
        },
      },

      // Constraints
      min: this.startDisplayTarget.min,
      max: undefined,
      minRange: 2,
      maxRange: undefined, // No limit to booking durations
      returnFormat: "iso8601",

      // Localization
      locale: mobiscroll.locale[this.localeValue],

      onChange: (event) => {
        let start
        let end
        [start, end] = event.value || []

        this.startValueTarget.value = start
        this.endValueTarget.value = end
      },
    })
  }
}
