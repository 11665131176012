/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let SpotMapContainer;
import _ from "lodash";
import { connect } from "react-redux";
import SpotMap from "../components/spot_map";

export var mapStateToProps = function(globalState) {
  const localState = _.cloneDeep(globalState);
  const { length, width, depth, locale, dockParam } = localState

  const paynstayParams = new URLSearchParams({ length, width, depth });

  const extraState = { paynstayUrl: `/${locale}/docks/${dockParam}/paynstay?${paynstayParams}#choose-date` };

  return Object.assign({}, localState, extraState);
};

export default SpotMapContainer = connect(mapStateToProps)(SpotMap);
