import React from "react";
import InactiveMap from "./inactive_map";

const MapFallback = () => {
  return (
    <InactiveMap inactiveMessage={"Map is not loaded"} />
  );
};

export default MapFallback;
