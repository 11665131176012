/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import React from "react";
import t from "../../common/utils/i18n";

export default function SpotLegend(props) {
  const { available, unavailable, selected } = props;

  return <div id="spot-map-legend">
    <div>
      <span><img style={{width: "34px", height: "38px"}} src={available} /></span>
      {t("availableLabel", props)}
    </div>
    <div>
      <span><img style={{width: "34px", height: "38px"}} src={unavailable} /></span>
      {t("notAvailableLabel", props)}
    </div>
    <div>
      <span><img style={{width: "34px", height: "38px"}} src={selected} /></span>
      {t("selectedLabel", props)}
    </div>
  </div>;
}
