/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import React from "react";
import SpotSelect from "../containers/spot_select_container"
import Map from "../containers/map_container";
import MapFallback from "../../booking_editor/components/map_fallback"
import Submit from "../../booking_editor/containers/submit_container";
import PlaceAs from "../../booking_editor/containers/place_as_container";
import SpotLegend from "../../booking_editor/containers/spot_legend_container";
import t from "../../common/utils/i18n";
import Notice from "../../common/utils/notice";

export default class BookingEditor extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      googleMapsLoaded,
    } = this.props;

    const MapOrFallback = googleMapsLoaded ? Map : MapFallback

    return (
      <div id="booking-editor">
        <div id="spot-map">
          <MapOrFallback />
        </div>
        <SpotLegend />
        <div className="tw-p-5 tw-bg-white tw-border tw-border-base-300 tw-shadow-lg tw-rounded-b-lg sm:tw-p-9">
          <div className="tw-m-auto sm:tw-max-w-lg">
            <PlaceAs />
            <SpotSelect />
            {this._payTipHtml()}
            <Submit />
          </div>
        </div>
      </div>
    );
  }

  _payTipHtml() {
    const { showPayLinkHint, paynstayUrl } = this.props;

    if (showPayLinkHint) {
      const link = `<a href='${paynstayUrl}' target='_top'>${t("inlinePayLink", this.props)}</a>`;
      const message = <span dangerouslySetInnerHTML={{ __html: t("payTip", this.props, false, { link }) }}/>;
      return <Notice icon="info" message={message} />;
    }
  }
}
