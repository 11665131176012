/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { dom } from "./dom";
import FakeTimers from "@sinonjs/fake-timers";

var usesSystemTime = false;

export default function useSystemTime() {
  if ((__guard__(dom.find("meta[name='environment']"), x => x.content) === "test") && (__guard__(dom.find("meta[name='timecop']"), x1 => x1.content) === "true")) {
    if (usesSystemTime) { return; }
    usesSystemTime = true;

    const systemTimeString = dom.find("meta[name='application-time']").content;
    const systemTime = Date.parse(systemTimeString);
    return FakeTimers.install({now: systemTime, shouldAdvanceTime: true, advanceTimeDelta: 40, shouldClearNativeTimers: true});
  }
}

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
