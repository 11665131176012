import { customResponseError } from "./errors";
import * as ajaxClient from "./ajax_client";

const AjaxUnauthorizedResponseError = customResponseError("AjaxUnauthorizedResponseError")
const AjaxInvalidRequestResponseError = customResponseError("AjaxInvalidRequestResponseError")
const AjaxNonSuccessResponseError= customResponseError("AjaxNonSuccessResponseError")

export const post = async (url, params) =>
  successfulResponse(await ajaxClient.post(url, { params }))

export const patch = async (url, params) =>
  successfulResponse(await ajaxClient.patch(url, { params }))

export const get = async (url, params, {signal} = {}) =>
  successfulResponse(await ajaxClient.get(url, { query: params, signal }))

export const del = async (url) => // NOTE: delete is a keyword in javascript
  successfulResponse(await ajaxClient.del(url))

const successfulResponse = (response) => {
  switch(response.code) {
    case 200:
    case 201:
      return response.body
    case 422:
      throw(new AjaxInvalidRequestResponseError(`Invalid Request\n\nError: ${JSON.stringify(response.body)}\n`, response))
    case 401:
      throw(new AjaxUnauthorizedResponseError("Unauthorized Response\n\n", response))
    default:
      throw(new AjaxNonSuccessResponseError("Unsuccessful Response\n\n", response))
  }
};
