import { Controller } from "@hotwired/stimulus";
import moment from "moment-timezone";
import ClosingTime from "../booking_editor/models/closing_time";
import { dom } from "../sparkles/dom_auto_install";

export default class DockClosingMessageController extends Controller {
  static targets = ["countdown"];

  initialize() {
    this.locale = dom.find("meta[name='locale']").content;
    this.timeZone = this.data.get("timeZone");
    this.closingTime = new ClosingTime(this.timeZone);
    this.dockHardClosingTime = this.closingTime.dockHardClosingTime();
  }

  connect() {
    if (this._isShown()) { this._update(); }
    this._startTimer();
  }

  disconnect() {
    this._stopTimer();
  }

  _update() {
    if (this._isShown()) {
      if (this._diff() > 0) {
        this.element.show();
        this.countdownTarget.innerHTML = this._humanTimeRemaining();
      } else {
        this.element.hide();
        this._stopTimer();
      }
    }
  }

  _humanTimeRemaining() {
    return moment.duration(this._diff()).locale(this.locale).humanize();
  }

  _isShown() {
    return !this.closingTime.isBeforePaddedDockSoftClosing();
  }

  _now() {
    return this.closingTime.local();
  }

  _diff() {
    return (this.dockHardClosingTime.unix() - this._now().unix()) * 1000;
  }

  _startTimer() {
    this.interval = window.setInterval(this._update.bind(this), 1000);
  }

  _stopTimer() {
    if (this.interval) { return window.clearInterval(this.interval); }
  }
}
