import { Controller } from "@hotwired/stimulus"
import * as mobiscroll from "@mobiscroll/javascript"

export default class ReservationCalendarController extends Controller {
  static targets = ["start", "end", "calendar"]
  static values = {
    locale: String,
    display: String,
    firstAvailableDate: String,
    unavailableFrom: String,
    unavailableDates: Array,
    rangeStartLabel: String,
    rangeStartHelp: String,
    rangeEndLabel: String,
    rangeEndHelp: String,
    maxRange: Number,
  }
  connect() {
    this.mobiscroll = mobiscroll.datepicker(this.calendarTarget, {
      // Style
      theme: "material",
      themeVariant: "light",
      controls: ["calendar"],
      select: "range",
      display: "inline",
      showRangeLabels: true,
      rangeStartLabel: this.rangeStartLabelValue,
      rangeStartHelp: this.rangeStartHelpValue,
      rangeEndLabel: this.rangeEndLabelValue,
      rangeEndHelp: this.rangeEndHelpValue,
      showInput: false,
      showWeekNumbers: false,
      inputStyle: "outline",

      // Constraints
      minRange: 2,
      maxRange: this.maxRangeValue,
      returnFormat: "iso8601",
      rangeEndInvalid: true,

      // Localization
      locale: mobiscroll.locale[this.localeValue],

      // Callbacks
      onInit: (_event, instance) => {
        const start = this.startTarget.value
        const end = this.endTarget.value

        this.#setAvailability({
          instance,
          firstAvailableDate: this.firstAvailableDateValue,
          unavailableDates: this.unavailableDatesValue,
          unavailableFrom: this.unavailableFromValue,
        })

        if (this.firstAvailableDateValue) {
          instance.setTempVal([start, end])
          instance.setVal([start, end])
        }
      },

      onChange: (event, instance) => {
        const [start, end] = event.value || []

        const startChanged = this.startTarget.value != start

        this.startTarget.value = start
        this.endTarget.value = end
        this.startTarget.dispatchEvent(new Event("change"))
        this.endTarget.dispatchEvent(new Event("change"))

        if (!start) {
          instance.setActiveDate("start")
        } else if (!end) {
          instance.setActiveDate("end")
        } else if (!startChanged) {
          instance.setActiveDate("start")
        } else {
          instance.setVal([start])
          instance.setActiveDate("end")
        }
      },
    })
  }

  #setAvailability({
    instance,
    unavailableDates,
    unavailableFrom,
    firstAvailableDate,
  }) {
    if (firstAvailableDate) {
      instance.setOptions({
        invalid: [unavailableFrom].concat(unavailableDates),
        valid: undefined,
        min: firstAvailableDate,
        max: unavailableFrom,
        colors: this.#addStyle(unavailableDates),
      })
    } else {
      instance.setOptions({
        valid: [],
        invalid: undefined,
      })
    }
  }

  #addStyle(unavailableDates) {
    return unavailableDates.map((date) => ({
      date: date,
      cellCssClass: "calendar__day--closed",
    }))
  }
}
