import Marker from "../models/leaflet/marker";
import Map from "../models/leaflet/map";
import DockController from "./dock_controller";
import MapSearchController from "./map_search_controller";
import { getCookie } from "../../common/utils/cookie";

export default class MapController {
  constructor(element, props) {
    this.element = element;
    this.props = props;
    const { docks, locale, mapFilterColor, region, tileUrl } = this.props;
    this.docks = Object.values(docks || []);
    this.mapPositionCookieName = `dockMapPosition${region}`

    this.map = new Map(Object.assign({}, { mapFilterColor, element: this.element, tileUrl }, this.mapPosition()))
    new MapSearchController(this.map.leafletMap, this.mapPositionCookieName);
    this.markers = [];

    this.dockController = new DockController(this.map, { locale });
    this.drawDocks();
  }

  drawDocks() {
    this.docks.forEach(dock => {
      const marker = new Marker(dock, this.props.assets);
      this.dockController.draw(dock, marker);
    });
  }

  mapPosition() {
    let lat, lng, zoom;
    let storedPosition = getCookie(this.mapPositionCookieName);
    if (storedPosition) {
      [lat, lng, zoom] = storedPosition.split("_");
      return { lat, lng, zoom };
    } else {
      return ({ lat, lng, zoom } = this.props);
    }
  }
}
