import { Controller } from "@hotwired/stimulus"
import * as mobiscroll from "@mobiscroll/javascript"
import * as ajax from "../common/utils/ajax"
import _ from "lodash"

export default class extends Controller {
  static values = {
    locale: String,
    availabilityUrl: String,
    display: String,
    firstAvailableDate: String,
    unavailableFrom: String,
    unavailableDates: Array,
    paynstay: Boolean,
    isCompatible: Boolean,

    noLongerAvailableText: String,
    rangeStartLabel: String,
    rangeStartHelp: String,
    rangeEndLabel: String,
    rangeEndHelp: String,
  }

  static classes = ["loading"]
  static targets = [
    "start",
    "end",
    "calendar",
    "incompatibleMessage",
    "missingMeasurementsMessage",
    "noDatesMessage"
  ]
  static outlets = ["spot-availability-form", "spot-map"]

  connect() {
    this.newUserInteraction = false;
    this.mobiscroll = mobiscroll.datepicker(this.calendarTarget, {
      // Style
      theme: "material",
      themeVariant: "light",
      controls: ["calendar"],
      select: "range",
      display: "inline",
      showRangeLabels: true,
      rangeStartLabel: this.rangeStartLabelValue,
      rangeStartHelp: this.rangeStartHelpValue,
      rangeEndLabel: this.rangeEndLabelValue,
      rangeEndHelp: this.rangeEndHelpValue,
      showInput: false,
      showWeekNumbers: true,
      // inputStyle: 'outline',

      // Constraints
      minRange: 2,
      maxRange: undefined, // No limit to booking durations
      returnFormat: "iso8601",
      rangeEndInvalid: true, // Departure allowed on first unavailable date

      // Localization
      locale: mobiscroll.locale[this.localeValue],
      dateFormat: "YYYY-MM-DD", // Overrides locale default. Consider removing and updating specs.
      firstDay: 1, // Hard-coded for europe

      // Callbacks
      onInit: (_event, instance) => {
        const start = this.startTarget.value
        const end = this.endTarget.value

        instance.setTempVal([start, end])
        instance.setVal([start, end])

        this.#setAvailability({
          instance,
          isCompatible: this.isCompatibleValue,
          firstAvailableDate: this.firstAvailableDateValue,
          unavailableDates: this.unavailableDatesValue,
          unavailableFrom: this.unavailableFromValue
        })

        if (!(start && end)) {
          this.#hideSpotMap()
        }

        if (this.#isPaynstay) {
          instance.setActiveDate("end")
        }
      },
      onCellClick: (event, instance) => {
        // Clear error message on cell click
        this.newUserInteraction = true;
        instance.setOptions({headerText: ""})
      },
      onChange: (event, instance) => {
        // Prevent specs from clicking the old component when we're expecting a new in response:
        this.#hideSpotMap()

        let start
        let end
        const eventValue = event.value || []

        if (eventValue.length > 0) {
          [start, end] = eventValue

          if (!this.#isPaynstay) {
            if (!start) {
              instance.setActiveDate("start")
            } else if (!end) {
              instance.setActiveDate("end")
            } else if (start == this.startTarget.value) {
              instance.setActiveDate("start")
            } else {
              end = null
              instance.setVal([start])
              instance.setActiveDate("end")
            }
          }

          this.startTarget.value = start
          this.endTarget.value = end
        } else {
          this.startTarget.value = null
          this.endTarget.value = null
        }

        if (this.#isPaynstay) {
          this.startTarget.value = this.firstAvailableDateValue
          instance.setVal([this.firstAvailableDateValue, end])
          instance.setActiveDate("end")
        }

        if (start && end) {
          this.#submitSpotAvailabilityForm()
        } else {
          this.#hideSpotMap()
        }
      },
      onActiveDateChange: (_event, instance) => {
        if (this.#isPaynstay) {
          requestAnimationFrame(() => instance.setActiveDate("end"))
        }
      },
    })
  }

  async loadDateAvailability({ length, width, depth }) {
    const paynstay = this.#isPaynstay

    const response = await this.#requestAvailability({
      length,
      width,
      depth,
      paynstay,
    })

    if (!response) {
      return // request was cancelled
    }

    const instance = this.mobiscroll
    const isCompatible = response.is_compatible
    const unavailableDates = response.unavailable_dates
    const unavailableFrom = response.unavailable_from
    const firstAvailableDate = response.first_available_date

    this.#setAvailability({instance, isCompatible, unavailableDates, unavailableFrom, firstAvailableDate})


    if (this.startTarget.value && this.endTarget.value) {
      this.#submitSpotAvailabilityForm()
    }
  }

  hideMissingMeasurementsOverlay() {
    this.#isMissingMeasurements = false
  }

  showMissingMeasurementsOverlay() {
    this.#isMissingMeasurements = true
    this.#isIncompatible = false
    this.#hasNoDates = false
  }

  async #requestAvailability(params) {
    this.abortable?.abort()
    this.abortable = new AbortController()

    try {
      this.isLoading = true
      const response = await ajax.get(this.availabilityUrlValue, params, {
        signal: this.abortable.signal,
      })
      this.isLoading = false

      return response
    } catch (error) {
      if (error instanceof DOMException && error.name == "AbortError") {
        // no-op
        return null
      } else {
        throw error
      }
    }
  }

  set isLoading(isLoading) {
    const classes = this.loadingClass.split(" ")

    if (isLoading) {
      this.element.classList.add(...classes)
    } else {
      this.element.classList.remove(...classes)
    }
  }

  #setAvailability({instance, isCompatible, unavailableDates, unavailableFrom, firstAvailableDate}) {
    this.#isIncompatible = false
    this.#hasNoDates = false
    this.newUserInteraction = false

    if (isCompatible) {
      if(firstAvailableDate) {
        let [beforeStart, beforeEnd] = instance.getTempVal() || []
        // Missing values are sometimes undefined, sometimes empty
        // strings, so let's normalize to empty strings
        beforeStart ||= ""
        beforeEnd ||= ""

        instance.setOptions({
          invalid: [unavailableFrom].concat(unavailableDates),
          valid: undefined,
          min: firstAvailableDate,
          max: unavailableFrom,
          colors: this.#addStyle(unavailableDates)
        })

        //
        // It takes at least 3 event loops between setting new invalid
        // dates and mobiscroll updating the instance value, so we need
        // to wait before checking if the values have changed after
        // updating availability. Sadly just waiting for 3 animation
        // frames doesn't work in staging / production, so we wait for
        // 200ms instead.
        //
        setTimeout(() => {
          // to protect ourselves from capybara
          if(this.newUserInteraction) {
            return
          }

          let [afterStart, afterEnd] = instance.getTempVal() || []
          // Missing values are sometimes undefined, sometimes empty
          // strings, so let's normalize to empty strings
          afterStart ||= ""
          afterEnd ||= ""

          // if the selection has changed after updating
          // availability, that means the selected dates are no
          // longer available and the user needs to pick new dates
          if(beforeStart !== afterStart || beforeEnd !== afterEnd) {
            if(this.#isPaynstay && this.firstAvailableDateValue) {
              // for paynstay, we should still keep arrival date
              // set if possible
              instance.setVal([this.firstAvailableDateValue])
            } else {
              instance.setVal([])
            }
            instance.setOptions({
              headerText: this.noLongerAvailableTextValue
            })
          }
        }, 200)
      } else {
        this.#hasNoDates = true
        instance.setOptions({
          valid: [],
          invalid: undefined
        })
      }
    } else {
      this.#isIncompatible = true
      instance.setOptions({
        valid: [],
        invalid: undefined,
      })
    }
  }


  #addStyle(unavailableDates) {
    return unavailableDates.map((date) => ({
      date: date,
      cellCssClass: "calendar__day--closed",
    }))
  }

  set #isIncompatible(isInCompatible) {
    if (isInCompatible) {
      this.incompatibleMessageTarget.classList.remove("hidden")
      this.#hideSpotMap()
    } else {
      this.incompatibleMessageTarget.classList.add("hidden")
    }
  }

  set #isMissingMeasurements(isMissingMeasurements) {
    if (isMissingMeasurements) {
      this.missingMeasurementsMessageTarget.classList.remove("hidden")
      this.#hideSpotMap()
    } else {
      this.missingMeasurementsMessageTarget.classList.add("hidden")
    }
  }

  set #hasNoDates(hasNoDates) {
    if (hasNoDates) {
      this.noDatesMessageTarget.classList.remove("hidden")
    } else {
      this.noDatesMessageTarget.classList.add("hidden")
    }
  }

  get #isPaynstay() {
    return this.paynstayValue
  }

  #submitSpotAvailabilityForm = _.debounce(() => {
    if (this.hasSpotAvailabilityFormOutlet) {
      this.spotAvailabilityFormOutlet.submit()
    }
  }, 300)

  #hideSpotMap() {
    if (this.hasSpotMapOutlet) {
      this.spotMapOutlet.hide()
    }
  }
}
