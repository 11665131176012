/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { setCookie } from "../../common/utils/cookie";

export default class MapSearchController {
  constructor(map, cookieName) {
    map.on("moveend", function () {
      const center = map.getCenter();

      return setCookie(
        cookieName,
        `${center.lat}_${center.lng}_${map.getZoom()}`,
        { expires: 7 } // 1 week
      );
    });
  }
}
