/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

let Polygon;
import Base from "../../../common/models/base";

export default Polygon = (function() {
  Polygon = class Polygon extends Base {
    static initClass() {
      this.get("points", function() {
        return this.gPolygon.getPath().getArray().map(point => ({
          lat: point.lat(),
          lng: point.lng()
        }));
    });
    }

    constructor(options) {
      if (options == null) { options = {}; }
      super(options);
      this.gPolygon = options.gPolygon;
      if (!this.gPolygon) { this.gPolygon = new google.maps.Polygon({
        paths: options.points,
        strokeColor: "transparent",
        strokeOpacity: 1.0,
        fillColor: "#828fa0",
        fillOpacity: 1.0
      }); }
    }

    draw(map) {
      return this.gPolygon.setMap(map.gMap);
    }
  };
  Polygon.initClass();
  return Polygon;
})();
