/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let docksMapReducer;
import _ from "lodash";

export default docksMapReducer = function(originalState, action) {
  if (originalState == null) { originalState = {}; }
  const newState = _.cloneDeep(originalState);
  return newState;
};
