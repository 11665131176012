export const customError = (name) => {
  return class extends Error {
    constructor(message, context = "") {
      super(message + context.toString());
      this.name = name;
    }
  };
}

export const customResponseError = (name) => {
  return class extends Error {
    constructor(message, response) {
      super(message + `Code: ${response.code}\n` + response.requestContext.toString());
      this.name = name;
    }
  };
}
