import { Controller } from "@hotwired/stimulus";
import { Client } from "@twilio/conversations";

export default class extends Controller {
  static targets = [
    "tokenExpiredAlert",
    "template",
    "messageList",
    "bodyInput"
  ];
  static values = {
    token: String,
    ref: String,
  };
  static classes = ["authored"];

  async connect() {
    console.debug("[Conversation]", "connect()")
    this.#scrollToBottomOnImageLoad(this.messageListTarget)

    this.client = new Client(this.tokenValue)

    this.client.on("initFailed", ({ error }) => console.error("[Conversation]", "Failed to initialize the Twilio Client", error))
    this.client.on("connectionStateChanged", state => console.debug("[Conversation]", "connectionStateChanged", { state }))

    this.client.on("tokenExpired", () => {
      this.tokenExpiredAlertTarget.classList.remove("hidden");
    })

    if (this.hasRefValue) {
      this.client.on("initialized", async () => {
        console.debug("[Conversation]", "Listening to conversation", this.refValue)
        this.conversation = await this.client.getConversationBySid(this.refValue)
        this.conversation.on("messageAdded", message => this.#appendMessage(message))

        this.#readAll()
      })
    }
  }

  disconnect() {
    console.debug("[Conversation]", "disconnect()")
    this.client?.shutdown()
  }

  #readAll(conversation = this.conversation) {
    conversation.setAllMessagesRead()
  }

  #appendMessage(message) {
    const fragment = this.templateTarget.content.cloneNode(true)
    const frame = fragment.querySelector("turbo-frame")
    const src = frame.getAttribute("src").replace("$message_ref", message.sid)
    frame.setAttribute("src", src)
    frame.setAttribute("id", message.sid)

    this.messageListTarget.appendChild(frame)

    requestAnimationFrame(() => this.#scrollToBottom())
  }

  onMessageShown(_event) {
    this.#readAll()
    this.#scrollToBottomOnImageLoad(_event.target)
  }

  #scrollToBottomOnImageLoad(container) {
    container.querySelectorAll("img").forEach((image) => {
      image.addEventListener("load", () => this.#scrollToBottom(), { once: true })
    })
    this.#scrollToBottom()
  }

  #scrollToBottom() {
    const container = this.messageListTarget.parentElement

    container.scrollTo({ top: container.scrollHeight })
  }

  onAttachmentChange(event) {
    this.bodyInputTarget.toggleAttribute("required", event.target.files.length == 0)
  }
}
