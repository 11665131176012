/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

let Map;
import Base from "../../../common/models/base";

export default Map = (function() {
  Map = class Map extends Base {
    static initClass() {
      this.delegate("controls", "zoom", {to() { return this.gMap; }});
    }

    constructor(options) {
      if (options == null) { options = {}; }
      super(options);
      const lat = parseFloat(options.lat);
      const lng = parseFloat(options.lng);
      const zoom = parseInt(options.zoom);
      const center = { lat, lng };

      this.gMap = new google.maps.Map(options.element, { zoom, center, mapTypeId: options.mapType });

      const CoordMapType = function(tileSize) {
        this.tileSize = tileSize;
      };

      CoordMapType.prototype.getTile = function(coord, zoom, ownerDocument) {
        const div = ownerDocument.createElement("div");
        div.style.width = `${this.tileSize.width}px`;
        div.style.height = `${this.tileSize.height}px`;
        div.style.backgroundColor = options.mapFilterColor || "#253d5b";
        div.style.opacity = options.mapFilterOpacity || 0.5;
        return div;
      };

      const mapSize = new google.maps.Size(256, 256);
      this.gMap.overlayMapTypes.insertAt(0, new CoordMapType(mapSize));
    }
  };
  Map.initClass();
  return Map;
})();
