/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

let Icon;
export default Icon = function(url, param) {
  if (param == null) { param = { small: false }; }
  const { small } = param;
  return {
    url,
    anchor: anchor(),
    scaledSize: scaledSize(small)
  };
};

var anchor = () => point(17, 30);

var scaledSize = function(small) {
  if (small) { return new size(24, 28); } else { return new size(34, 38); }
};

var point = function(x, y) {
  if (typeof google !== 'undefined' && google !== null) { return new google.maps.Point(x, y); } else { return [x, y]; }
};

var size = function(w, h) {
  if (typeof google !== 'undefined' && google !== null) { return new google.maps.Size(w, h); } else { return [w, h]; }
};
