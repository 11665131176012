/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS203: Remove `|| {}` from converted for-own loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let ToggleController;
import { Controller } from "@hotwired/stimulus";
import { dom } from "../sparkles/dom_auto_install";

export default ToggleController = (function() {
  ToggleController = class ToggleController extends Controller {
    static initClass() {
      this.targets = ["section"];
    }

    initialize() {
      this.sections = {};
      this.mode = this.element.dataset.mode;
      this.currentInput = this.element.find("input[data-toggle-current-input]");
      const initialByInput = this._getInputValue();

      this.sectionTargets.forEach(element => {
        const name = element.dataset.toggleSection;

        if (this.sections[name]) {
          return this.sections[name].elements.push(element);
        } else {
          return this.sections[name] = {
            open: (
              element.dataset.toggleInitiallyOpen ||
              (initialByInput === name) ||
              element.find(".form-group.has-error") ||
              (window.location.hash === `#section-${element.dataset.toggleSection}`)
            ),
            elements: [element],
            trigger: this.element.find(`[data-toggle-target='${name}']`)
          };
        }
    });

      return this._update();
    }

    toggle(event) {
      event.preventDefault();
      const target = event.target.closest("[data-action]");
      const section = this.sections[target.dataset.toggleTarget];

      if (this.mode === "single") {
        for (let name in this.sections) { const s = this.sections[name]; s.open = false; }
        section.open = true;
      } else {
        section.open = !section.open;
      }

      return this._update();
    }

    _update() {
      this._clearInputValue();

      return (() => {
        const result = [];
        for (let name of Object.keys(this.sections || {})) {
          const section = this.sections[name];
          if (section.open) {
            section.elements.forEach(element => element.style.removeProperty("display"));
            if (section.trigger != null) {
              section.trigger.parentElement.classList.remove("dropright");
            }
            if (section.trigger != null) {
              section.trigger.classList.add("active");
            }
            result.push(this._setInputValue(name));
          } else {
            section.elements.forEach(element => element.style.display = "none");
            if (section.trigger != null) {
              section.trigger.parentElement.classList.add("dropright");
            }
            result.push((section.trigger != null ? section.trigger.classList.remove("active") : undefined));
          }
        }
        return result;
      })();
    }

    _setInputValue(value) {
      if (this.currentInput && (this.mode === "single")) { return this.currentInput.value = value; }
    }

    _getInputValue() {
      if (this.currentInput && (this.mode === "single")) { return this.currentInput.value; }
    }

    _clearInputValue() {
      if (this.currentInput) { return this.currentInput.value = ""; }
    }
  };
  ToggleController.initClass();
  return ToggleController;
})();
