import Marker from "../models/gmap/marker";
import Polygon from "../models/gmap/polygon";
import Map from "../models/gmap/map";

export default class MapController {
  constructor(element, props) {
    this.element = element;
    this.props = props;
    const { zoom, mapType, mapFilterColor } = this.props;

    this.spots = Object.values(this.props.spots || {})
    this.piers = Object.values(this.props.piers || {})

    const { lat, lng } = this.spots[0].point
    this.map = new Map({ element: this.element, lat, lng, zoom, mapType, mapFilterColor })

    this.#drawSpots(this.map)
    this.#drawPiers(this.map)
  }

  #drawSpots(map) {
    this.spots.forEach(spot => {
      const marker = new Marker({
        position: spot.point,
        icon: this.props.icon
      })

      marker.draw(map)
    });
  }

  #drawPiers(map) {
    this.piers.forEach(pier => {
      const polygon = new Polygon({points: pier.points});
      polygon.draw(map);
    });
  }

  remove() {
    this.element.replaceChildren()
  }
}
