/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import attachRootComponent from "../common/react/bootstrap";
import reducer from "./reducers/reducer";
import Dock from "./models/dock";
import DocksMap from "./components/docks_map";

export var docksMap = () => attachRootComponent("#docks-map-container", DocksMap, initInitialState, reducer);

var initInitialState = function(props) {
  const { lat, lng, zoom, locale, assets, region, tileUrl } = props;
  return { lat, lng, zoom, locale, assets, region, tileUrl, docks: Dock.deserialize(props.docks) };
};
