/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS203: Remove `|| {}` from converted for-own loops
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import _ from "lodash";

export default class Spot {
  static deserialize(spotAttributes) {
    return spotAttributes.reduce(
      function(acc, attributes) {
        const spot = new Spot({
          id: attributes.id,
          number: attributes.number,
          isPaynstayOnly: attributes.paynstay_only || false,
          point: { lat: parseFloat(attributes.lat), lng: parseFloat(attributes.lng) },
          totalAmount: attributes.total_amount,
          description: attributes.description,
          isCurrent: attributes.is_current,
          isAvailable: attributes.is_available
        });
        acc[spot.id] = spot;
        return acc;
      }
    , {});
  }

  static isEqual(spots, otherSpots) {
    let key, spot;
    const a = ((() => {
      const result = [];
      for (key of Object.keys(spots || {})) {
        spot = spots[key];
        result.push({ id: key, available: spot.isAvailable, current: spot.isCurrent });
      }
      return result;
    })());
    const b = ((() => {
      const result1 = [];
      for (key of Object.keys(otherSpots || {})) {
        spot = otherSpots[key];
        result1.push({ id: key, available: spot.isAvailable, current: spot.isCurrent });
      }
      return result1;
    })());

    return _.isEqual(a, b);
  }

  constructor(attributes) {
    if (attributes == null) { attributes = {}; }
    this.id = attributes.id;
    this.number = attributes.number;
    this.isPaynstayOnly = attributes.isPaynstayOnly;
    this.point = attributes.point || { lat: attributes.lat, lng: attributes.lng };
    this.totalAmount = attributes.totalAmount;
    this.description = attributes.description;
    this.isCurrent = attributes.isCurrent;
    this.isAvailable = attributes.isAvailable;
  }
}
