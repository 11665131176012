import { dom } from "../../sparkles/dom";
import { fetchWithEnhancedErrors } from "./fetch_with_enhanced_errors"

export const get = async (url, { query, signal } = {}) => {
  const fullUrl = query ? `${url}?${new URLSearchParams(query)}` : url

  return await fetchWithEnhancedErrors(fullUrl, {
    method: "GET",
    headers: _jsonHeaders,
    ...(signal && { signal }), // Only add `signal` if it's defined
  })
}

export const post = async (url, { params } = {}) =>
  await fetchWithEnhancedErrors(url, {
    method: "POST",
    body: JSON.stringify(params),
    headers: _csrfHeaders()
  })

export const patch = async (url, { params } = {}) =>
  await fetchWithEnhancedErrors(url, {
    method: "PATCH",
    body: JSON.stringify(params),
    headers: _csrfHeaders()
  })

export const del = async (url) =>
  await fetchWithEnhancedErrors(url, {
    method: "DELETE",
    headers: _csrfHeaders()
  })

const _csrfHeaders = () => ({
  ..._jsonHeaders,
  "X-CSRF-Token": _csrfToken()
});

const _jsonHeaders = {
  "Accept": "application/json",
  "Content-Type": "application/json"
};

const _csrfToken = () => dom.find("meta[name='csrf-token']")?.content;
