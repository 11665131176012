/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { setCurrentSpot } from "../actions/actions";

export default class SpotController {
  constructor(map, options) {
    this.map = map;
    if (options == null) { options = {}; }
    this.dispatch = options.dispatch;
    this.markers = options.markers;
  }

  draw(spot, marker) {
    marker.draw(this.map);
    return this._attachEvents(spot, marker);
  }

  _attachEvents(spot, marker) {
    return this._attachMarkerClick(spot, marker);
  }

  _attachMarkerClick(spot, marker) {
    return marker.onClick(() => {
      if (marker.isAvailable) {
        this.dispatch(setCurrentSpot(spot.id));
        return this._setCurrentMarker(marker);
      }
    });
  }

  _setCurrentMarker(currentMarker) {
    this.markers.forEach(marker => marker.deselect());
    return currentMarker.select();
  }
}
