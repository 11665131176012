/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS203: Remove `|| {}` from converted for-own loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let bookingEditorReducer;
import _ from "lodash";

export default bookingEditorReducer = function(originalState, action) {
  if (originalState == null) { originalState = {}; }
  const newState = _.cloneDeep(originalState);

  switch (action.type) {
    case "SET_CURRENT_SPOT":
      for (let id of Object.keys(newState.spots || {})) { const spot = newState.spots[id]; spot.isCurrent = false; }
      if (action.spotId && (action.spotId !== "")) {
        newState.spots[action.spotId].isCurrent = true;

        window.dispatchEvent(
          new CustomEvent("spotChanged", {
            detail: { spotNumber: newState.spots[action.spotId].number },
          })
        )
      }
      return newState
    default:
      return originalState;
  }
};
