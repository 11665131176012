/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import moment from "moment-timezone";

import { dom } from "./dom";

import initDisableWith from "./disable_with";
import { handleCookieConsentHandlers } from "./cookies";
import InitConfirmPromts from "./confirm_prompt";
import useSystemTime from "./stub_time";

export var sparkles = function() {
  dom.install(window);

  useSystemTime();

  InitConfirmPromts(); // make sure to init first as it should take precedence over other events
  initDisableWith();
  handleCookieConsentHandlers();

  return dom.find("meta[name='client-time']").setAttribute("content", moment().format());
};

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
