/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
// This is a hack that monkey patches removeChild and insertBefore so that React is able to work with
// other libraries that manipulates the DOM.
// One of them being the built in Google Translate feature in Google Chrome.
// The issue is documented here https://bugs.chromium.org/p/chromium/issues/detail?id=872770
// The side-effect of this fix is performance.

let domReplacementFix;
export default domReplacementFix = function() {
  if ((typeof Node === "function") && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;

    Node.prototype.removeChild = function(child) {
      if (child.parentNode !== this) {
        if (typeof console !== 'undefined' && console !== null) {
          console.warn("Cannot remove a child from a different parent", child, this);
        }
        return child;
      }
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;

    return Node.prototype.insertBefore = function(newNode, referenceNode) {
      if (referenceNode && (referenceNode.parentNode !== this)) {
        if (typeof console !== 'undefined' && console !== null) {
          console.warn("Cannot insert before a reference node from a different parent", referenceNode, this);
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, arguments);
    };
  }
};
