import { Controller } from "@hotwired/stimulus";
import { patch } from "../common/utils/ajax";

export default class CheckboxController extends Controller {
  initialize() {
    this.url = this.data.get("url");
  }

  toggle() {
    patch(this.url);
  }
}
