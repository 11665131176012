import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spotNumber"]

  connect() {
    this.spotChangedListener = (event) => {
      this.#spotNumber = event.detail.spotNumber
    }
    window.addEventListener("spotChanged", this.spotChangedListener)
  }

  disconnect() {
    window.removeEventListener("spotChanged", this.spotChangedListener)
  }

  submit() {
    this.element.requestSubmit()
  }

  set #spotNumber(spotNumber) {
    this.spotNumberTarget.value = spotNumber
  }
}
