import { Controller } from "@hotwired/stimulus"
import _ from "lodash"
import isValidDecimalInput from "../sparkles/is_valid_decimal_input"

export default class extends Controller {
  static targets = ["dimension", "length", "width", "depth"]
  static outlets = ["booking-calendar"]

  checkEnableCalendar() {
    if (this.#isMissingBoatDimensions()) {
      this.bookingCalendarOutlet.showMissingMeasurementsOverlay()
    } else {
      this.bookingCalendarOutlet.hideMissingMeasurementsOverlay()
      this.bookingCalendarOutlet.isLoading = true
      this.#refreshCalendarAvailability()
    }
  }

  bookingCalendarOutletConnected(outlet, element) {
    if (this.#isMissingBoatDimensions()) {
      this.bookingCalendarOutlet.showMissingMeasurementsOverlay()
    } else {
      this.bookingCalendarOutlet.hideMissingMeasurementsOverlay()
    }
  }

  #isMissingBoatDimensions() {
    return this.dimensionTargets.some((input) => !isValidDecimalInput(input));
  }

  get length() {
    return this.lengthTarget.value
  }

  get width() {
    return this.widthTarget.value
  }

  get depth() {
    return this.depthTarget.value
  }

  #refreshCalendarAvailability = _.debounce(() => {
    if(this.hasBookingCalendarOutlet) {
      this.bookingCalendarOutlet.loadDateAvailability({
        length: this.length,
        width: this.width,
        depth: this.depth
      })
    }
  }, 300)
}
