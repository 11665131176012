import { Controller } from "@hotwired/stimulus"

export default class TemplateTogglerController extends Controller {
  static targets = [ "link", "container", "stage", "template" ]
  static values = { activeClass: String }

  initialize() {
    this._copyStageToTemplate()
  }

  toggle(event) {
    event.preventDefault()

    const link = event.currentTarget

    this._setStage(link)
    this._setActiveLink(link)
  }

  _copyStageToTemplate() {
    const stageTemplate = this._createTemplate(this.stageTarget.innerHTML)
    this.containerTarget.append(stageTemplate)
  }

  _createTemplate(content) {
    const stageTemplate = document.createElement("template")
    stageTemplate.dataset.templateTogglerTarget = "template"
    stageTemplate.dataset.template = this.stageTarget.dataset.template
    stageTemplate.innerHTML = content

    return stageTemplate
  }

  _setActiveLink(link) {
    this._inactivateLinks()
    this._activateLink(link)
  }

  _setStage(link) {
    const name = link.dataset.target
    const template = this._currentTemplate(name)

    this.stageTarget.innerHTML = template.innerHTML
  }

  _inactivateLinks() {
    this.linkTargets.forEach(target => {
      target.classList.remove(this.activeClassValue)
  })
  }

  _activateLink(link) {
    link.classList.add(this.activeClassValue)
  }

  _currentTemplate(name) {
    return this.templateTargets.find(target =>
      target.dataset.template == name
    )
  }
}
