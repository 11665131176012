/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let ClosingTime;
import moment from "moment-timezone";
import "../../sparkles/stub_time_auto_install";

export default ClosingTime = (function() {
  ClosingTime = class ClosingTime {
    static initClass() {
      this.closingHour = 10;
      this.hardClosingMinute = 15;
    }

    constructor(timeZone) {
      this.timeZone = timeZone;
    }

    isToday(date) {
      return this.parse(date).isSame(this.local(), "day");
    }

    isBeforeDockSoftClosing() {
      return this.local().isBefore(this.dockSoftClosingTime());
    }

    isBeforePaddedDockSoftClosing() {
      return this.local().isBefore(this.paddedDockSoftClosingTime());
    }

    isBeforeDockHardClosing() {
      return this.local().isBefore(this.dockHardClosingTime());
    }

    dockSoftClosingTime() {
      return this.local().hours(ClosingTime.closingHour).startOf("hour");
    }

    paddedDockSoftClosingTime() {
      return this.dockSoftClosingTime().clone().subtract(5, "minutes");
    }

    dockHardClosingTime() {
      return this.local().hours(ClosingTime.closingHour).minutes(ClosingTime.hardClosingMinute).startOf("minute");
    }

    local() {
      return this.parse(moment());
    }

    parse(date) {
      if ((typeof date === "string") || date instanceof Date) {
        return moment.tz(date, this.timeZone);
      } else if (moment.isMoment(date)) {
        return moment.tz(date.format(), this.timeZone);
      } else {
        return moment.tz(date.withTime, this.timeZone);
      }
    }
  };
  ClosingTime.initClass();
  return ClosingTime;
})();
