import { Controller } from "@hotwired/stimulus"
import isValidDecimalInput from "../sparkles/is_valid_decimal_input"

export default class extends Controller {
  connect() {
    // Use correct keyboard depending on step size
    if(Number.isInteger(parseFloat(this.element.step))) {
      this.element.inputMode = "numeric"
    } else {
      this.element.inputMode = "decimal"
    }
    this.element.type = "text"

    this.normalizeAndValidate({target: this.element});
    this.roundValue({target: this.element});
  }

  normalizeAndValidate(event) {
    const input = event.target;
    input.classList.remove("has-error");

    if(input.value === "" || input.value === null) { return }
    input.value = input.value.replace(",", ".").replace(/[^0-9.]/, "");

    if(!isValidDecimalInput(input)) {
      input.classList.add("has-error");
    }
  }

  roundValue(event) {
    if(event.target.value === "" || event.target.value === null) { return }

    const number = parseFloat(event.target.value);

    if(isNaN(number)) {
      event.target.value = null;
    } else if(event.target.inputMode === "numeric") {
      // Convert to integer if numeric
      event.target.value = Number(number.toFixed(0));
    } else {
      // Keep 2 digits precision at most otherwise
      event.target.value = Number(number.toFixed(2));
    }
  }
}
