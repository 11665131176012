import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  // Note: "main" and "thumb" are expected to be img elements
  static targets = ["lightbox", "main", "thumb"]

  disconnect() {
    this.unlockScroll()
  }

  close() {
    this.lightboxTarget.classList.add("hidden")
    this.unlockScroll()
  }

  open() {
    this.lightboxTarget.classList.remove("hidden");
    this.lockScroll();
  }

  goto({ params: { src } }) {
    this.mainTarget.src = src;

    this.thumbTargets.forEach((element) => {
      element.classList.remove("selected");

      if (element.src == src) {
        element.classList.add("selected")
      }
    })
  }

  lockScroll() {
    document.body.classList.add("lightbox-open")
  }

  unlockScroll() {
    document.body.classList.remove("lightbox-open")
  }
}
