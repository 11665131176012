/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let SpotLegendContainer;
import { connect } from "react-redux";
import SpotLegend from "../components/spot_legend";

export var assetsFromProps = ({ assets, translations }) => ({
  available: assets.icon_spot_available,
  unavailable: assets.icon_spot_unavailable,
  selected: assets.icon_spot_selected,
  translations
});

const mapStateToProps = assetsFromProps;

export default SpotLegendContainer = connect(mapStateToProps)(SpotLegend);
