/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS203: Remove `|| {}` from converted for-own loops
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let t;
import _ from "lodash";
import dig from "./dig";

export default t = function(camelKey, { translations }, pluralizable, variables, options) {
  if (variables == null) { variables = {}; }
  if (options == null) { options = {}; }
  const keys = camelKey.split(".").map(key => _.snakeCase(key).replace(/_(\d+)/, '$1'));

  let text = dig(keys, translations);
  if (pluralizable) { text = text[Object.values(pluralizable)[0] === 1 ? "one" : "other"]; }

  if (text) {
    if (pluralizable) { text = text.replace(`%{${Object.keys(pluralizable)[0]}}`, Object.values(pluralizable)[0]); }

    for (let key of Object.keys(variables || {})) {
      const value = variables[key];
      text = text.replace(`%{${key}}`, value);
    }

    return text;
  } else if (options.fallback) {
    return options.fallback;
  } else {
    return `Missing translation with ${keys.join(".")}`;
  }
};
