import { Controller } from "@hotwired/stimulus"

import Map from "../docks_map/models/leaflet/map";
import L from "leaflet";

export default class extends Controller {
  static targets = ["map"]

  static values = {
    zoom: Number,
    lat: Number,
    lng: Number,
    iconUrl: String,
  }

  connect() {
    const map = new Map({
      zoom: this.zoomValue,
      lat: this.latValue,
      lng: this.lngValue,
      element: this.mapTarget
    })

    const icon = L.icon({
      iconUrl: this.iconUrlValue,
      iconSize: [34, 38],
      iconAnchor: [17, 38]
    })
    L.marker([this.latValue, this.lngValue], { icon }).addTo(map.leafletMap)
  }
}
