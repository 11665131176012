export default class Map {
  constructor(options = {}) {
    const { lat, lng } = options
    const zoom = parseInt(options.zoom)

    const mapId = options.element.dataset.mapId
    this.gMap = new google.maps.Map(options.element, {
      zoom,
      center: { lat, lng },
      mapTypeId: options.mapType,
      mapId,
      disableDefaultUI: true,
      gestureHandling: "none",
      keyboardShortcuts: false
    })
    this.gMap.overlayMapTypes.insertAt(0, new CoordMapType(options.mapFilterColor))
  }
}

class CoordMapType {
  tileSize = new google.maps.Size(256, 256)
  color = "#253d5b"

  constructor(color) {
    if (color) this.color = color;
  }

  getTile(coord, zoom, ownerDocument) {
    const div = ownerDocument.createElement("div")
    div.style.width = `${this.tileSize.width}px`
    div.style.height = `${this.tileSize.height}px`
    div.style.backgroundColor = this.color
    div.style.opacity = 0.5
    return div
  }
}