export default function isValidDecimalInput(input) {
  const value = input.value;

  if(value === "" || value === null) {
    if(input.required) {
      return false
    } else {
      return true
    }
  }

  const number = parseFloat(value);

  if(isNaN(number)) {
    return false
  }

  if(input.min) {
    const min = parseFloat(input.min);
    if(value < min) {
      return false
    }
  }

  if(input.max) {
    const max = parseFloat(input.max);
    if(value > max) {
      return false
    }
  }

  return true;
}
