/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { Controller } from "@hotwired/stimulus";
import { dom } from "../sparkles/dom_auto_install";

export default class ScrollController extends Controller {
  initialize() {
    this.targetSelector = this.data.get("target");
    return this.scopeSelector = this.data.get("scope");
  }

  start(event) {
    event.preventDefault();
    return this.scrollX(-this.currentScroll());
  }

  back(event) {
    event.preventDefault();
    return this.scrollX(-this.number());
  }

  forward(event) {
    event.preventDefault();
    return this.scrollX(this.number());
  }

  selector(event) {
    event.preventDefault();
    return this.scrollX(this.targetElementX());
  }

  targetElement() {
    return this._targetElement || (this._targetElement = this.element.closest(this.scopeSelector).find(this.targetSelector));
  }

  targetElementX() {
    if (this.targetElement()) {
      let firstRect;
      const offset = __guard__(this.element.closest(this.scopeSelector).find("#sidebar"), x => x.clientWidth) || 0;

      // NOTE: jsdom can't handle getClientRects()
      if ((firstRect = this.targetElement().getClientRects()[0])) {
        return firstRect.left - offset;
      } else {
        return 0;
      }
    }
  }

  number() {
    return parseInt(this.targetSelector);
  }

  scrollX(x) {
    return window.scrollBy({left: x, behavior: "smooth"});
  }

  currentScroll() {
    return window.pageXOffset;
  }
}

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
