import { Controller } from "@hotwired/stimulus";
import _ from "lodash"

export default class extends Controller {
  static targets = ["click", "noDisableOnTurboSubmit"];

  connect() {
    document.addEventListener("turbo:submit-start", this.onTurboSubmit);
    document.addEventListener("turbo:before-fetch-request", this.onTurboBeforeFetchRequest)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-start", this.onTurboSubmit);
    document.removeEventListener("turbo:before-fetch-request", this.onTurboBeforeFetchRequest)
  }

  click() {
    this.clickTargets.forEach((target) => {
      // The target might have left the DOM after the debounce, only click if still there.
      if (document.body.contains(target)) {
        target.click();
      }
    });
  }

  onTurboBeforeFetchRequest(event) {
    // This is a hack to make the form submit as application/x-www-form-urlencoded on Safari.
    // Find out why the form is sent with Content-Type: plain/text on Safari and fix it.
    const request = event.detail.fetchOptions
    if(request.method === "POST") {
      request.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8"
    }
  }

  clickSubmit() {
    let targetsToReenable = this.noDisableOnTurboSubmitTargets
                                .filter((target) => !target.disabled)
                                .map((target) => [target, target.value]);

    if (this.previousTurboSubmit) {
      this.previousTurboSubmit.stop();
    }

    this.click();

    targetsToReenable.forEach(([target, valueBeforeDisable]) => {
      target.value = valueBeforeDisable;
      target.disabled = false;
    })
  }

  preventSubmitOnEnterKey(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
    }
  }

  clickSubmitWithDebounce = _.debounce(this.clickSubmit, 300);

  #onTurboSubmit(event) {
    this.previousTurboSubmit = event.detail.formSubmission;
  }
}
