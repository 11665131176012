import { Controller } from "@hotwired/stimulus"

// This controller is used to sync the value of two input fields, when
// we need to submit two forms independently, but with the same data.
// For example, when we want to GET-submit to refresh a cost breakdown.

export default class extends Controller {
  static targets = ["input"]

  setChecked(event) {
    const input = this.#findInput(event.params.id)
    input.value = event.target.checked
    this.dispatch("update")
  }

  setValue(event) {
    const input = this.#findInput(event.params.id)
    input.value = event.target.value
    this.dispatch("update")
  }

  #findInput(id) {
    return this.inputTargets.find((input) => input.dataset.inputSyncId === id)
  }
}
