/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

let Marker;
import Base from "../../../common/models/base";
import Icon from "../../../common/models/gmap/icon";

export default Marker = (function() {
  Marker = class Marker extends Base {
    static initClass() {
      this.get("point", function() { return { lat: this.gMarker.position.lat(), lng: this.gMarker.position.lng() }; });
    }

    constructor(spot, assets) {
      let point;
      if (spot == null) { spot = {}; }
      if (assets == null) { assets = {}; }
      super(spot);
      ({ point, isCurrent: this.isCurrent, isAvailable: this.isAvailable, gMarker: this.gMarker } = spot);
      const { icon_spot_selected, icon_spot_available, icon_spot_unavailable } = assets;

      if (!this.gMarker) { this.gMarker = new google.maps.Marker({
        position: point,
        clickable: this.isAvailable,
        title: "google-maps-spot-marker"
      }); }

      this.inactiveIcon = new Icon(icon_spot_available);
      this.activeIcon = new Icon(icon_spot_selected);
      this.unavailableIcon = new Icon(icon_spot_unavailable);

      this.gMarker.setIcon(this.getIcon());
    }

    draw(map) {
      return this.gMarker.setMap(map != null ? map.gMap : undefined);
    }

    select() {
      return this.gMarker.setIcon(this.activeIcon);
    }

    deselect() {
      return this.gMarker.setIcon(this.getIcon());
    }

    onClick(callback) { return this.addListener("click", callback); }
    addListener(eventName, callback) { return this.gMarker.addListener(eventName, callback); }

    getIcon() {
      if (this.isCurrent) {
        return this.activeIcon;
      } else if (this.isAvailable) {
        return this.inactiveIcon;
      } else {
        return this.unavailableIcon;
      }
    }
  };
  Marker.initClass();
  return Marker;
})();
