/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let SpotSelectContainer;
import _ from "lodash";
import moment from "moment-timezone";
import { connect } from "react-redux";
import SpotSelect from "../components/spot_select";
import t from "../../common/utils/i18n";

export var mapStateToProps = function(globalState) {
  const localState = _.cloneDeep(globalState);
  let { spots, from, to } = localState;

  const currentSpot = Object.values(spots).find(spot => spot.isCurrent);
  const currentSpotDescription = currentSpot != null ? currentSpot.description : undefined;
  const currentSpotId = currentSpot != null ? currentSpot.id : undefined;

  const availableSpots = _.filter(spots, spot => spot.isAvailable);
  spots = Object.values(availableSpots).sort((a, b) => a.number.localeCompare(b.number, "en", {numeric: true}));
  const numberOfSpots = spots.length;

  let numberOfNights = from && to ? moment.duration(to.diff(from)).asDays() : 0;
  numberOfNights = Math.round(numberOfNights); // NOTE: in case diff was across DST
  const spotOptions = spots.map(function(spot) {
    const text = `${spot.number} (${spot.totalAmount} ${t("shortSpotPriceInfo", localState, {nights: numberOfNights})})`;
    return { id: spot.id, text };});

  const extraState = { currentSpot, currentSpotId, currentSpotDescription, numberOfSpots, spotOptions };
  return Object.assign({}, localState, extraState);
};

export default SpotSelectContainer = connect(mapStateToProps)(SpotSelect);
