import { Controller } from "@hotwired/stimulus";
import moment from "moment-timezone";

// This class is intended for localizing dates to the user's timezone.

export default class extends Controller {
  // 'date' is optional, when sent as a value it will override the element's value
  // 'attributes' is an array of attributes to localize, e.g. ['value', 'min', 'max']
  static values = {
    attributes: Array,
    date: String,
    format: { type: String, default: "YYYY-MM-DD" },
  };

  connect() {
    let date;

    if (this.hasDateValue) {
      date = this.dateValue;
    }

    this.attributesValue.forEach((attribute) => {
      date = date || this.element[attribute];

      this.element[attribute] = moment(date)
        // localize the date to the user's timezone:
        .tz(moment.tz.guess())
        .format(this.formatValue);
    });
  }
}
