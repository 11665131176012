/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import React from "react";
import { setCurrentSpot } from "../actions/actions";
import t from "../../common/utils/i18n";
import Notice from "../../common/utils/notice";

export default class SpotSelect extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  onSelect(event) {
    return this.props.dispatch(setCurrentSpot(event.target.value));
  }

  render() {
    const {
      spotOptions,
      currentSpot,
      currentSpotId,
      currentSpotDescription,
      isPaynstay,
      from,
      to,
      numberOfSpots,
      disabled
    } = this.props;

    const optionsHTML = spotOptions.map(({ id, text }) => <option value={id} key={id}>{text}</option>);

    const spotDescriptionHtml = currentSpotDescription ?
      <Notice message={currentSpotDescription} /> : undefined;

    const prompt = isPaynstay ?
      <option value="" key="blank">- {t("spotSelectPrompt", this.props)} -</option> : undefined;

    if ((currentSpot || isPaynstay) && from && to && numberOfSpots) {
      return <div className="form-group select required booking_spot_id u-text-center u-margin-bottom-small">
        <label className="control-label select required" htmlFor="booking_spot_id">
          {t("selected_spot_label", this.props)}&nbsp;
          <span className="text-muted required-star" title="mandatory"><abbr title="required">*</abbr>
          </span>&nbsp;
        </label>
        <select
          className="form-control select required"
          name="booking[spot_id]"
          id="booking_spot_id"
          value={currentSpotId}
          onChange={this.onSelect.bind(this)}
          disabled={disabled}
        >
          {prompt}
          {optionsHTML}
        </select>
        {spotDescriptionHtml}
      </div>;
    } else {
      return "";
    }
  }
}
