import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["summary", "form"]

  open() {
    this.summaryTarget.classList.add("tw-hidden")
    this.formTarget.classList.remove("tw-hidden")
  }
}
