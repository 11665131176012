import { Controller } from "@hotwired/stimulus"
import { Client } from "@twilio/conversations"
import _ from "lodash"

class UnreadStorage {
  #key = "stimulus.controllers.twilio.unread"

  constructor(defaultValue) {
    this.storage = window.sessionStorage
    this.defaultValue = defaultValue
  }

  get() {
    const value = this.storage.getItem(this.#key)
    return value ? JSON.parse(value) : this.defaultValue
  }

  set(value) {
    this.storage.setItem(this.#key, JSON.stringify(value))
  }

  update(fn) {
    const oldValue = this.get()
    const newValue = fn(oldValue)

    if (!_.isEqual(oldValue, newValue)) {
      this.set(newValue)
      return newValue
    }
  }
}

export default class extends Controller {
  static values = { token: String }

  #unread = new UnreadStorage({
    conversations: {},
    total: undefined
  })

  #client = undefined
  #conversations = {}

  get unread() {
    return this.#unread.get()
  }

  initialize() {
    console.debug("[Twilio]", "initialize()")

    if (!this.hasTokenValue) {
      console.debug("[Twilio]", "::initialize", "no token, skipping")
      return
    }

    this.#client = new Client(this.tokenValue)
    this.#client.on("initFailed", ({ error }) => console.error("[Twilio]", "::initFailed", error))
    this.#client.on("initialized", () => console.debug("[Twilio]", "::initialized"))
    this.#client.on("initialized", () => this.#registerAllConversations(this.#client))
    this.#client.on("tokenExpired", () => console.error("[Twilio]", "::tokenExpired"))
  }

  async #registerAllConversations(client) {
    const register = this.#register.bind(this)
    // NOTE: Order here is important, see https://www.twilio.com/docs/conversations/best-practices-sdk-clients#javascript
    client.on("conversationAdded", register)

    // Iterate through all pages of subscribed conversations so that we may subscribe to them all.
    for(;;) {
      let page = await client.getSubscribedConversations()
      page.items.forEach(register)

      if (page.hasNextPage) {
        page = await page.nextPage()
      } else {
        break
      }
    }
  }

  async #register(conversation) {
    if (conversation.sid in this.#conversations) return;
    this.#conversations[conversation.sid] = {}

    this.#updateConversation(conversation, undefined)
    conversation.on("updated", ({ conversation }) => this.#updateConversation(conversation))
    await this.#updateConversation(conversation)
  }

  async #updateConversation(conversation) {
    if (this.#conversations[conversation.sid].updating) return;
    let unread = null

    try {
      this.#conversations[conversation.sid].updating = true
      unread = await conversation.getUnreadMessagesCount()
    } finally {
      this.#conversations[conversation.sid].updating = false
    }

    return this.#update(conversation.sid, unread)
  }

  #update(conversationRef, unread) {
    const updated = this.#unread.update(({ conversations: oldConversations, ...rest }) => {
      const conversations = {
        ...oldConversations,
        [conversationRef]: unread
      }

      const total = Object.values(conversations).reduce((sum, unread) => sum + (unread ?? 0), 0)

      return { ...rest, total, conversations }
    })

    if (updated) {
      this.#updated()
    }
  }

  #updated() {
    this.dispatch("updated", {
      detail: { unread: this.unread }
    })
  }
}

