/* eslint no-console:0 */

import "@kollegorna/cocoon-vanilla-js";
import { Turbo } from "@hotwired/turbo-rails";

// Allows us to opt-in individually by setting data-turbo="true" on links and forms that we want to have Turbo enabled on.
Turbo.session.drive = false;

import { sparkles } from "./sparkles/init";
import { docksMap } from "./docks_map/docks_map";

document.addEventListener("turbo:load", (event) => {
  sparkles();
  docksMap();
  console.debug("application.js initialized");
});

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import CheckboxSelectAll from "stimulus-checkbox-select-all"

const application = Application.start()

const context = require.context("./controllers", true, /.js/)
application.load(definitionsFromContext(context))

application.register("checkbox-select-all", CheckboxSelectAll)

console.debug("application.js loaded")
