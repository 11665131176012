/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import React from "react";
import t from "../../common/utils/i18n";
import Icon from "../../common/utils/icon";

export default function Notice(props) {
  const text = props.message || t(props.messageKey, props.translations);
  const iconName = props.icon || "info";
  const messageClasses = props.small ? "u-text-small u-text-muted" : "";
  const marginBottom = props.marginBottom || "u-margin-bottom-medium";

  return <div className={`l-media ${marginBottom} u-margin-top-small`}>
    <div className="l-media__img">
      <Icon name={iconName} />
    </div>
    <div className="l-media__body">
      <p className={messageClasses}>{text}</p>
    </div>
  </div>;
}
