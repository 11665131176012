/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let MapContainer;
import _ from "lodash";
import { connect } from "react-redux";
import Map from "../components/map";
import t from "../../common/utils/i18n";

export var mapStateToProps = function(globalState) {
  const localState = _.cloneDeep(globalState);
  const { from, to, length, width, depth, isCompatible, isCamping} = localState;

  const isActive = from && to && length && width && depth && isCompatible;

  if (isActive) {
    localState.currentSpot = Object.values(localState.spots).find(spot => spot.isCurrent);
  } else {
    localState.spots = _.reduce(localState.spots, (function(mem, spot, id) {
      mem[id] = Object.assign({}, spot, { isCurrent: false, isAvailable: false });
      return mem;
    }), {});
  }

  const inactiveMessage = (() => {
    if (!from || !to || !length || !width || !depth) {
      return t("noDurationMapMessage", globalState);
    }
  })();

  const extraState = {
    isActive,
    inactiveMessage,
    mapFilterColor: isCamping ? "#2a2b15" : "#253d5b",
    mapFilterOpacity: inactiveMessage ? 0.8 : 0.5
  };

  return Object.assign({}, localState, extraState);
};

export default MapContainer = connect(mapStateToProps)(Map);
