/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import React from "react";
import MapController from "../controllers/map_controller";

export default class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(_prevProps) {
    const element = document.querySelector("#map");
    this.mapController = new MapController(element, this.props);
    return window.map = this.mapController;
  }

  render() {
    return <div id="map-wrapper">
      <div id="map" className="large" />
    </div>;
  }
}
