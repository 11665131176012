import React from "react";

const InactiveMap = ({ inactiveMessage }) => {
  return (
    <div id="map-wrapper" className="inactive">
      <h6>{inactiveMessage}</h6>
      <div id="map" className="large"></div>
    </div>
  );
};

export default InactiveMap;
