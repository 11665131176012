import { Controller } from "@hotwired/stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = ["input", "results", "result", "submit"];

  connect() {
    this.onInputChange = _.debounce(this.onInputChange, 300);
    this.inputTarget.addEventListener("input", this.onInputChange);
  }

  disconnect() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("input", this.onInputChange);
    }
  }

  onInputChange = () => this.fetch();

  fetch() {
    this.submitTarget.click();
    this.optionIndex = -1;
  }

  onInputBlur() {
    // when an item is selected by mouse click, the blur event will be initiated before the click event and the click will
    // never be triggered. Delay the close to avoid this issue.
    setTimeout(() => this.close(), 200);
  }

  clickSelect(event) {
    this.#select(event.target);
    this.close();
  }

  #select(listElement) {
    if (listElement) {
      this.inputTarget.value = listElement.getAttribute("data-autocomplete-value");
    }
  }

  close() {
    if(this.hasResultsTarget) {
      this.resultsTarget.classList.add("hidden");
    }
  }

  clear() {
    this.inputTarget.value = "";
    this.close();
    this.optionIndex = -1;
  }

  handleEnterKey(event) {
    event.preventDefault();
    this.close();
  }

  handleUpKey(event) {
    if (this.resultTargets.length < 1) {
      return;
    }
    this.optionIndex = _.max([this.optionIndex - 1, 0]);
    this.#showSelected();
    this.#select(this.resultTargets[this.optionIndex]);

    event.preventDefault();
    if (this.hasResultTargets) {
      event.stopPropagation();
    }
  }

  handleDownKey(event) {
    if (this.resultTargets.length < 1) {
      return;
    }
    this.optionIndex = _.min([this.optionIndex + 1, this.resultTargets.length - 1]);
    this.#showSelected();
    this.#select(this.resultTargets[this.optionIndex]);

    event.preventDefault();
    if (this.hasResultTargets) {
      event.stopPropagation();
    }
  }

  #showSelected() {
    this.resultTargets.forEach((element, optionIndex) => {
      if (optionIndex !== this.optionIndex) {
        element.classList.remove("selected");
      } else {
        element.classList.add("selected");
      }
    });
  }
}
