/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import { Controller } from "@hotwired/stimulus";
import { dom } from "../sparkles/dom_auto_install";

export default class NoticeController extends Controller {
  more() {
    return this.element.querySelector(".modal").show();
  }
}
