/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
export default class Pier {
  static deserialize(pierAttributes) {
    return pierAttributes.reduce(
      function(acc, attributes) {
        const pier = new Pier({
          id: attributes.id,
          points: attributes.points.map(point => ({
            lat: parseFloat(point.lat),
            lng: parseFloat(point.lng)
          }))
        });
        acc[pier.id] = pier;
        return acc;
      }
    , {});
  }

  constructor(attributes) {
    if (attributes == null) { attributes = {}; }
    this.id = attributes.id;
    this.points = attributes.points || [];
  }
}
