/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let dig;
export default dig = (path, object) => path.reduce((function(acc, value) {
  if (acc && acc[value]) {
    return acc[value];
  }
}), object);
