import { Controller } from "@hotwired/stimulus"
import _ from "lodash"

class DismissedStorage {
  #key = "stimulus.controllers.unread_badge.dismissed"

  constructor() {
    this.storage = window.sessionStorage
    this.defaultValue = false
  }

  get() {
    const value = this.storage.getItem(this.#key)
    return value ? JSON.parse(value) : this.defaultValue
  }

  set(value) {
    this.storage.setItem(this.#key, JSON.stringify(value))
  }
}

export default class extends Controller {
  static targets = [ "badge" ]
  static values = {
    conversationRef: String,
    unreadClass: { type: String, default: "unread-badge" }
  }
  static outlets = [ "twilio" ]

  #unreadValue(state) {
    if (this.hasConversationRefValue) {
      return state.conversations[this.conversationRefValue]
    } else {
      return state.total
    }
  }

  twilioOutletConnected(outlet, element) {
    element.addEventListener("twilio:updated", this.#updated)
    this.#update(outlet.unread)
  }

  #updated = _.debounce((event) => {
    this.#undismiss()

    this.#update(event.detail.unread);
  }, 2000);

  #update(state) {
    const unread = this.#unreadValue(state)
    this.badgeTarget.classList.toggle(this.unreadClassValue, unread > 0)
    this.badgeTarget.classList.toggle("dismissed", this.dismissed)
  }

  #dismissed = new DismissedStorage

  get dismissed() {
    return this.#dismissed.get()
  }

  dismiss() {
    this.#dismissed.set(true)
    this.badgeTarget.classList.add("dismissed")
  }

  #undismiss() {
    this.#dismissed.set(false)
    this.badgeTarget.classList.remove("dismissed")
  }
}
