/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import _ from "lodash";

export default class Dock {
  static deserialize(dockAttributes) {
    return dockAttributes.reduce(
      function(acc, attributes) {
        const dock = new Dock({
          id: attributes.id,
          param: attributes.param,
          name: attributes.name,
          point: { lat: parseFloat(attributes.lat), lng: parseFloat(attributes.lng) },
          isAvailable: attributes.is_available
        });
        acc[dock.id] = dock;
        return acc;
      }
    , {});
  }

  constructor(attributes) {
    if (attributes == null) { attributes = {}; }
    this.id = attributes.id;
    this.param = attributes.param;
    this.name = attributes.name;
    this.point = attributes.point || { lat: attributes.lat, lng: attributes.lng };
    this.isAvailable = attributes.isAvailable;
  }
}
