import React from "react";
import InactiveMap from "../../booking_editor/components/inactive_map";
import ActiveMap from "./active_map";

const Map = ({ isActive, inactiveMessage, ...props }) => {
  return (
    <div>
      {isActive ? (
        <ActiveMap {...props} />
      ) : (
        <InactiveMap inactiveMessage={inactiveMessage} />
      )}
    </div>
  );
};

export default Map;
