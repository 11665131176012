/*
 * decaffeinate suggestions:
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

let Map;
import Base from "../../../common/models/base";
import L from "leaflet";

export default Map = (function() {
  Map = class Map extends Base {
    static initClass() {
      this.delegate("controls", "zoom", {to() { return this.leafletMap; }});
    }

    constructor(options) {
      if (options == null) { options = {}; }
      super(options);
      const lat = parseFloat(options.lat);
      const lng = parseFloat(options.lng);
      const zoom = parseInt(options.zoom);
      const center = { lat, lng };

      this.leafletMap = L.map(options.element, {
        zoom,
        center: [center.lat, center.lng],
        scrollWheelZoom: false,
      });
      this.leafletMap.attributionControl.setPrefix(''); // Don't show the 'Powered by Leaflet' text.

      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(this.leafletMap);
    }
  };
  Map.initClass();
  return Map;
})();
