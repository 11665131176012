/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import React from "react";

export default function Icon(props) {
  let classes = "c-icon";
  if (props.classes) { classes = `${classes} ${props.classes}`; }

  return <svg className={`${classes}`}>
    <use xlinkHref={`#${props.name}`}></use>
  </svg>;
}
