import { Controller } from "@hotwired/stimulus"
import reportException from "../honeybadger"

export default class StripePaymentController extends Controller {
  static values = {
    publishableKey: String,
    clientSecret: String,
    returnUrl: String,
    defaultErrorMessage: String,
    locale: String,
    linkEmail: String,
    linkPhone: String,
    linkName: String,
  }

  static targets = [
    "payment",
    "submit",
    "message",
    "stripeFailureTemplate"
  ]

  #messageAutoHide = null

  connect() {
    if (!globalThis.Stripe) {
      return this.#error()
    }

    this.stripe = globalThis.Stripe(this.publishableKeyValue, { locale: this.localeValue })

    this.elements = this.stripe.elements({
      appearance: { theme: "stripe" },
      clientSecret: this.clientSecretValue
    })

    const paymentElement = this.elements.create("payment", {
      layout: "tabs",
      defaultValues: {
        billingDetails: {
          email: this.linkEmailValue,
          phone: this.linkPhoneValue,
          name: this.linkNameValue,
        },
      },
    })
    paymentElement.on("ready", () => this.#disabled = false)
    paymentElement.on("loaderstart", () => this.#initialized = true)
    paymentElement.on("loaderror", ({ error }) => {
      console.error(error)
      reportException(error, {
        component: "StripePaymentController",
        action: "loaderror",
        message: error.message
      })
      this.#error()
    })
    paymentElement.mount(this.paymentTarget)
  }

  #error() {
    this.#loading = false
    this.element.replaceChildren(this.stripeFailureTemplateTarget.content.cloneNode(true))
  }

  async submit(event) {
    event.preventDefault()

    try {
      this.#disabled = true
      this.#loading = true

      // @see https://stripe.com/docs/js/payment_intents/confirm_payment
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: this.returnUrlValue
        },
      })

      // @see https://stripe.com/docs/api/errors#errors-message
      if (this.#isUserError(error)) {
        // User can take action to retry
        this.#message = error.message
      } else if (this.#isUnexpectedState(error) || this.#isConnectionError(error)) {
        // User cannot take action to retry, need to start over
        this.#error()
      } else {
        this.#message = this.defaultErrorMessageValue

        console.error(error)

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        reportException(error, {
          component: "StripePaymentController",
          action: "submit",
          message: error.message
        })
      }
    } finally {
      this.#loading = false
      this.#disabled = false
    }
  }

  set #initialized(newValue) {
    this.element.dataset.initialized = newValue
  }

  set #disabled(newValue) {
    if(this.hasSubmitTarget) {
      this.submitTarget.disabled = newValue
    }
  }

  set #loading(newValue) {
    if (newValue) {
      this.element.dataset.loading = true;
    } else {
      delete this.element.dataset.loading;
    }
  }

  set #message(newValue) {
    clearTimeout(this.#messageAutoHide)
    this.messageTarget.textContent = newValue

    if (newValue) {
      this.#messageAutoHide = setTimeout(() => this.#message = null, 5000)
    }
  }

  // From the docs:
  //
  //   When the error type is card_error or validation_error, you can
  //   display the error message in error.message directly to your user. An
  //   error type of invalid_request_error could be due to an invalid
  //   request or 3DS authentication failures.
  //
  // 3DS authentication result in this error code:
  //   https://docs.stripe.com/error-codes#payment-intent-authentication-failure
  //
  // And the message is localized and clearly intended to the end
  // user, so we can treat that particular error as a user error.
  #isUserError(error) {
    const isValidationError = error.type === "validation_error"
    const isCardError = error.type === "card_error"
    const is3DSError = error.type === "invalid_request_error" && error.code === "payment_intent_authentication_failure"

    return isValidationError || isCardError || is3DSError
  }

  // This happens when the payment intent gets cancelled or is expired
  // See: https://docs.stripe.com/error-codes#payment-intent-unexpected-state
  #isUnexpectedState(error) {
    return error.type === "invalid_request_error" && error.code === "payment_intent_unexpected_state"
  }

  // This doesn't seem to be documented in the Stripe docs?
  // The Stripe Ruby Gem describes it as follows:
  //
  //   APIConnectionError is raised in the event that the SDK can't connect to
  //   Stripe's servers. That can be for a variety of different reasons from a
  //   downed network to a bad TLS certificate.
  #isConnectionError(error) {
    return error.type === "api_connection_error"
  }
}
