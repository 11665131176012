/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let SubmitContainer;
import _ from "lodash";
import { connect } from "react-redux";
import Submit from "../components/submit";
import ClosingTime from "../models/closing_time";

export var mapStateToProps = function(globalState) {
  let currentSpot, gtmClasses;
  const localState = _.cloneDeep(globalState);

  let {
    spots,
    length,
    width,
    depth,
    to,
    from,
    isPaynstay,
    locale,
    dockParam,
    timeZone,
    hasBooking
  } = localState;

  const closingTime = new ClosingTime(timeZone);

  if (spots) { currentSpot = Object.values(spots).find(spot => spot.isCurrent); }
  const validMesurements = (
    length && width && depth &&
    (typeof length === "number") && (typeof width === "number") && (typeof depth === "number")
  ) === true;
  const disabled = (
    !currentSpot ||
    (to === from) ||
    !from ||
    !to ||
    !validMesurements
  );

  if (!disabled) { gtmClasses = "gtm gtm-action gtm-purchase gtm-continue-step-2"; }

  const params = new URLSearchParams({ length, width, depth });
  const bookUrl = `/${locale}/docks/${dockParam}/book?${params}#choose-date`;
  const paynstayUrl = `/${locale}/docks/${dockParam}/paynstay?${params}#choose-date`;
  spots = _.filter(spots, spot => spot.isAvailable);
  const submitTextKey = isPaynstay ? "paySubmit" : "submit";

  const paynstayNotYetOpened = isPaynstay && closingTime.isBeforeDockHardClosing();
  const paynstayRightsWarningNoticeKey = hasBooking ? "paynstayRightsWarning" : "paynstayRightsWarningNoBooking";
  const paynstaySpotSelected = isPaynstay && !!currentSpot;

  const extraState = {
    validMesurements,
    disabled,
    gtmClasses,
    bookUrl,
    paynstayUrl,
    spots,
    submitTextKey,
    paynstayNotYetOpened,
    paynstayRightsWarningNoticeKey,
    paynstaySpotSelected
  };

  return Object.assign({}, localState, extraState);
};

export default SubmitContainer = connect(mapStateToProps)(Submit);
