import { Controller } from "@hotwired/stimulus"
import isGoogleMapsLoaded from "../common/utils/is_google_maps_loaded";

import Spot from "../booking_info/models/spot";
import Pier from "../booking_info/models/pier";
import MapController from "../booking_info/controllers/map_controller";

export default class extends Controller {
  static targets = ["map", "icon"]

  static values = {
    zoom: Number,
    mapType: String,
    spots: Array,
    piers: Array,
    camping: Boolean,
    assets: Object
  }

  #mapController = null

  connect() {
    this.mount()
  }

  disconnect() {
    this.unmount()
  }

  mount() {
    if (!isGoogleMapsLoaded()) {
      return
    }

    this.#mapController = new MapController(this.mapTarget, {
      zoom: this.zoomValue,
      spots: Spot.deserialize(this.spotsValue),
      piers: Pier.deserialize(this.piersValue, this.data.get("dockId")),
      mapType: this.mapTypeValue,
      mapFilterColor: this.campingValue ? "#2a2b15" : "#253d5b",
      icon: this.iconTarget,
    })
  }

  unmount() {
    this.#mapController?.remove()
    this.#mapController = null
  }
}
