import { Controller } from "@hotwired/stimulus";

export default class SiteHeaderController extends Controller {
  static targets = ["menu", "menuToggleButton"]

  get menuOpen() {
    return this.element.getAttribute("aria-expanded") === "true"
  }

  set menuOpen(value) {
    const open = value ? "true" : "false"
    this.element.setAttribute("aria-expanded", open)
    this.menuToggleButtonTarget.setAttribute("aria-expanded", open)
    this.menuTarget.setAttribute("aria-expanded", open)
  }

  toggleMenuOpen() {
    this.menuOpen = !this.menuOpen
  }
}