/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let MapContainer;
import _ from "lodash";
import { connect } from "react-redux";
import Map from "../components/map";

export var mapStateToProps = function(globalState) {
  const localState = _.cloneDeep(globalState);
  const { isCamping } = localState;

  const extraState = {
    mapFilterColor: isCamping ? "#2a2b15" : "#253d5b"
  };

  return Object.assign({}, localState, extraState);
};

export default MapContainer = connect(mapStateToProps)(Map);
