import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["expandable"]
  static classes = ["open", "closed"]

  open() {
    this.element.ariaExpanded = true
    this.expandableTarget.classList.remove(this.closedClass)
    this.expandableTarget.classList.add(this.openClass)
  }

  close() {
    this.element.ariaExpanded = false
    this.expandableTarget.classList.remove(this.openClass)
    this.expandableTarget.classList.add(this.closedClass)
  }

  closeAndStopPropagation(event) {
    event.stopPropagation()
    this.close(event)
  }
}
