/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import React from "react";
import Map from "../containers/map_container";

export default function DocksMap(props) {
  return <div id="docks-map">
    <Map />
  </div>;
}
