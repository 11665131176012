import Icon from "../../../common/models/gmap/icon";

export default class Marker {
  gMarker = undefined

  constructor({ position, icon }) {
    this.position = position
    this.icon = icon
  }

  async draw(map) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker")

    const position = this.position
    const content = document.importNode(this.icon.content, true)

    this.gMarker = new AdvancedMarkerElement({ position, content, map: map.gMap })
  }

  get point() {
    return {
      lat: this.gMarker.position.lat(),
      lng: this.gMarker.position.lng()
    }
  }
}
