/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import Spot from "../../booking_editor/models/spot";
import Pier from "../../booking_editor/models/pier";
import Marker from "../../booking_editor/models/gmap/marker";
import Polygon from "../../booking_editor/models/gmap/polygon";
import Map from "../../booking_editor/models/gmap/map";
import SpotController from "../../spot_map/controllers/spot_controller";
import PierController from "../../booking_editor/controllers/pier_controller";

export default class MapController {
  constructor(element, props) {
    let lat, lng, mapFilterColor, mapFilterOpacity, mapType, zoom;
    this.element = element;
    this.props = props;
    ({ lat, lng, zoom, mapType, assets: this.assets, mapFilterColor, mapFilterOpacity } = this.props);

    this.spots = Object.values(this.props.spots || {});
    this.piers = Object.values(this.props.piers || {});

    this.map = new Map({ element: this.element, lat, lng, zoom, mapType, mapFilterColor, mapFilterOpacity });
    this.markers = [];
    this.polygons = [];

    this.spotController = new SpotController(this.map, {dispatch: this.props.dispatch, markers: this.markers});
    this.pierController = new PierController(this.map);
    this.drawSpots(this.map);
    this.drawPiers(this.map);
  }

  updateSpots(spots) {
    this.spots = Object.values(spots || {});
    this.removeAllMarkers();
    return this.drawSpots();
  }

  drawSpots() {
    return this.spots.forEach(spot => {
      const marker = new Marker(spot, this.assets);
      this.markers.push(marker);
      return this.spotController.draw(spot, marker);
    });
  }

  drawPiers() {
    return this.piers.forEach(pier => {
      const polygon = new Polygon({points: pier.points});
      this.polygons.push(polygon);
      return this.pierController.draw(polygon);
    });
  }

  removeAllMarkers() {
    this.markers.forEach(marker => marker.draw(null));
    return this.markers = [];
  }
}
