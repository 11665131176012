/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import React from "react";
import Icon from "../../common/utils/icon";
import t from "../../common/utils/i18n";

export default class PlaceAs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, guestEmail: "" };
    this.props = props;
  }

  setPlaceAs(event) {
    this.setState({open: true});
    return event.preventDefault();
  }

  setBookSelf(event) {
    this.setState({open: false, guestEmail: ""});
    return event.preventDefault();
  }

  setGuestEmail(event) {
    return this.setState({guestEmail: event.target.value});
  }

  render() {
    const { isAdmin } = this.props;
    if (!isAdmin) { return ""; }

    return <React.Fragment>
      {this._toggleLink()}
      {this.state.open ? this._form() : undefined}
    </React.Fragment>;
  }

  _form() {
    return <div className="form-group select required u-text-center">
      <label className="control-label text required u-margin-bottom-medium u-margin-top-medium" htmlFor="booking_guest_email">
        {t("guestEmail", this.props)}
        <span className="text-muted required-star" title="mandatory">
          <abbr title="required">*</abbr>
        </span>&nbsp;

        <input
          type="email" className="text required c-input-group__input"
          placeholder="guest@example.com"
          required=""
          aria-required="true"
          aria-invalid="false"
          name="booking[guest_email]"
          id="booking_guest_email"
          value={this.state.guestEmail}
          onChange={this.setGuestEmail.bind(this)}
        />

        <p className="u-text-small u-text-muted u-margin-top-none" style={{fontWeight: "normal"}}>
          <Icon name="info" />{t("adminPlaceHint", this.props)}
        </p>
      </label>
    </div>;
  }

  _toggleLink() {
    let link;
    const classNames = "u-text-small u-text-muted u-margin-bottom-medium";

    return link = this.state.open ?
      <p className={classNames}>
        {t("bookSelfLinkPre", this.props)}&nbsp;
        <a href="#" id="book-self-link" onClick={this.setBookSelf.bind(this)}>{t("bookSelfLink", this.props)}</a>&nbsp;
        {t("bookSelfPost", this.props)}
      </p>
    :
      <p className={classNames}>
        {t("placeAsLinkPre", this.props)}&nbsp;
        <a href="#" id="place-as-link" onClick={this.setPlaceAs.bind(this)}>{t("placeAsLink", this.props)}</a>&nbsp;
        {t("placeAsPost", this.props)}
      </p>;
  }
}
