/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import _ from "lodash";
import moment from "moment-timezone";

export default class Spot {
  static deserialize(spotAttributes) {
    return spotAttributes.reduce(
      function(acc, attributes) {
        const spot = new Spot({
          id: attributes.id,
          number: attributes.number,
          point: { lat: parseFloat(attributes.lat), lng: parseFloat(attributes.lng) },
          description: attributes.description,
          isCurrent: true
        });
        acc[spot.id] = spot;
        return acc;
      }
    , {});
  }

  constructor(attributes) {
    if (attributes == null) { attributes = {}; }
    this.id = attributes.id;
    this.number = attributes.number;
    this.point = attributes.point || { lat: attributes.lat, lng: attributes.lng };
    this.description = attributes.description;
    this.isCurrent = attributes.isCurrent;
  }
}
