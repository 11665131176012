/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let attachRootComponent;
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { dom } from "../../sparkles/dom";
import { createElement } from "react";
import { Provider } from "react-redux";
import { render, unmountComponentAtNode } from "react-dom";
import domReplacementFix from "./dom_replacement_fix";

export default attachRootComponent = function(selector, component, stateInitializer, reducer) {
  let container = dom.find(selector);
  if (!container) return () => {};

  return attachRootComponentToElement(container, component, stateInitializer, reducer);
};

export function attachRootComponentToElement(element, component, stateInitializer, reducer) {
  domReplacementFix();

  const props = initProps(element);
  const initialState = stateInitializer(props);

  const store = initStore(initialState, reducer);

  const providerElement = createElement(Provider, { store }, createElement(component));
  render(providerElement, element);

  let unmounted = false
  return () => {
    if (unmounted) return;
    unmounted = true
    unmountComponentAtNode(element)
  }
}

var initProps = container => JSON.parse(container.getAttribute("data-react-props"));

const composeEnhancers =
  (typeof window === "object") &&
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose);

var initStore = (initialState, reducer) => createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);
