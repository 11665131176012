/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
export default class PierController {
  constructor(map) {
    this.map = map;
  }

  draw(polygon) {
    return polygon.draw(this.map);
  }
}
