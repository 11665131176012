import React from "react";
import MapController from "../controllers/map_controller";
import Spot from "../../booking_editor/models/spot";

export default class ActiveMap extends React.Component {
  componentDidMount() {
    this._initController();
  }

  componentDidUpdate(prevProps) {
    if (!Spot.isEqual(prevProps.spots, this.props.spots)) {
      this.mapController.updateSpots(this.props.spots);
    }

    if (prevProps.mapFilterOpacity !== this.props.mapFilterOpacity) {
      this._initController();
    }
  }

  render() {
    return (
      <div id="map-wrapper" className="active">
        <div id="map" className="large"></div>
      </div>
    );
  }

  _initController() {
    const element = document.querySelector("#map");
    this.mapController = new MapController(element, this.props);
    window.map = this.mapController;
  }
}
