import { Controller } from "@hotwired/stimulus"
import isValidDecimalInput from "../sparkles/is_valid_decimal_input"

export default class ReservationSidebarController extends Controller {
  static targets = ["boatDimension", "fromDate", "toDate", "disablingOverlay", "submitButton"]

  connect() {
    this.#checkDisablingOverlay()
    this.#checkDisablingSubmitButtons()
  }

  onBoatDimensionInput(event) {
    this.#checkDisablingOverlay()
    this.#checkDisablingSubmitButtons()
  }

  onDateInputChange(event) {
    this.#checkDisablingSubmitButtons()
  }

  #isMissingBoatDimensions() {
    return this.boatDimensionTargets.some((input) => !isValidDecimalInput(input))
  }

  #checkDisablingOverlay() {
    if (this.#isMissingBoatDimensions()) {
      this.disablingOverlayTarget.classList.remove("hidden")
    } else {
      this.disablingOverlayTarget.classList.add("hidden")
    }
  }

  #checkDisablingSubmitButtons() {
    if (this.#isMissingBoatDimensions() || this.fromDateTarget.value === "" || this.toDateTarget.value === "") {
      this.submitButtonTargets.forEach((button) => {
        button.disabled = true
      })
    } else {
      this.submitButtonTargets.forEach((button) => {
        button.disabled = false
      })
    }
  }
}
