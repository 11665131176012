/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import React from "react";
import t from "../../common/utils/i18n";
import Notice from "../../common/utils/notice";

export default class Submit extends React.Component {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.props = props;
  }

  render() {
    const { disabled, gtmClasses, submitTextKey } = this.props;

    return <div>
      {this._validationMessagesHtml()}
      <input
        ref={ this.buttonRef }
        type="submit"
        name="commit"
        id="booking-contine-button"
        value={t(submitTextKey, this.props)}
        disabled={disabled}
        className={`c-button c-button--block ${gtmClasses}`}
        data-disable-with={t("loading", this.props)}
      />
    </div>;
  }

  _validationMessagesHtml() {
    let html, notice;
    const {
      bookUrl,
      paynstayUrl,
      isCompatible,
      spots,
      from,
      to,
      validMesurements,
      paynstayNotYetOpened,
      paynstayRightsWarningNoticeKey,
      paynstaySpotSelected
    } = this.props;
    const validationMessages = [];

    if (!validMesurements) {
      validationMessages.push(t("blankMeasurement", this.props));
    } else if (!isCompatible) {
      validationMessages.push(`${t("noCompatibleSpots", this.props)} ${t("noCompatibleSpotsHint", this.props)}`);
    } else if (!from) {
      validationMessages.push(t("missingArrival", this.props));
    } else if (!to) {
      validationMessages.push(t("missingDeparture", this.props));
    } else if (isCompatible && !spots.length) {
      if (paynstayNotYetOpened) {
        const bookLink = `<a href='${bookUrl}' target='_top'>${t("pnsClosedBookLink", this.props)}</a>`;
        const reloadLink = `<a href='${paynstayUrl}'>${t("pnsClosedReloadLink", this.props)}</a>`;
        notice = t("paynstayNotYetOpened", this.props, false, { bookLink, reloadLink });
        html = <span dangerouslySetInnerHTML={{ __html: notice }} />;
        validationMessages.push(html);
      } else {
        validationMessages.push(t("noSpotsOnDuration", this.props));
      }
    }

    if (paynstaySpotSelected && !validationMessages.length) {
      const link = `<a href='${bookUrl}' target='_top'>${t("inlineBookLink", this.props)}</a>`;
      notice = t(paynstayRightsWarningNoticeKey, this.props, false, { link });
      html = <span dangerouslySetInnerHTML={{ __html: notice }} />;
      validationMessages.push(html);
    }

    if (validationMessages.length) {
      const lis = validationMessages.map((message, index) => <Notice key={`error-${index}`} icon="warning" message={message} />);
      return <div className="form-group select required booking_spot_id u-margin-bottom-small">{lis}</div>;
    }
  }
}
