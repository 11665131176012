/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
export default class Base {
  static get(name, body) {
    return Object.defineProperty(this.prototype, name, {configurable: true, get: body});
  }

  static set(name, body) {
    return Object.defineProperty(this.prototype, name, {configurable: true, set: body});
  }

  static delegate(...args) {
    const adjustedLength = Math.max(args.length, 1), names = args.slice(0, adjustedLength - 1), options = args[adjustedLength - 1];
    return names.forEach(name => {
      this.get(name, function() {
        return options.to.call(this)[name];
    });

      return this.set(name, function(value) {
        return options.to.call(this)[name] = value;
      });
    });
  }
}
