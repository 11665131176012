/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let InitConfirmPromts;
import { dom } from "./dom";

const attachPrompt = function(event, element) {
  // This stops event propagation completely, so that other handler do
  // not run before this one has completed.
  // In particular, we want the "disable-with" handler to run only
  // after confirmation.
  event.stopImmediatePropagation();
  if (confirm(element.dataset.confirm)) {
    delete element.dataset.confirm
    setTimeout(_ => event.target.dispatchEvent(new event.constructor(event.type, event)));
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export default InitConfirmPromts = function() {
  dom.findAll("form").on("submit", function(event) {
    let element = event.target.find("input[data-confirm], button[data-confirm]");
    if (element) {
      return attachPrompt(event, element);
    } else {
      return true;
    }
  });

  return dom.findAll("a[data-confirm]").on("click", function(event) {
    if (event.target.dataset.confirm) {
      return attachPrompt(event, this);
    } else {
      return true
    }
  });
};
