/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

let Marker;
import Base from "../../../common/models/base";
import L from "leaflet";

export default Marker = (function() {
  Marker = class Marker extends Base {
    static initClass() {
      this.get("point", function() { return { lat: this.leafletMarker.position.lat(), lng: this.leafletMarker.position.lng() }; });
    }

    constructor(dock, assets) {
      let point;
      let name;
      if (dock == null) { dock = {}; }
      if (assets == null) { assets = {}; }
      super(dock);
      ({ leafletMarker: this.leafletMarker, point, isAvailable: this.isAvailable, name } = dock);
      ({ icon_spot_available: this.icon_spot_available, icon_spot_unavailable: this.icon_spot_unavailable } = assets);

      if (!this.leafletMarker) { this.leafletMarker = L.marker(point, {
        clickable: this.isAvailable,
        title: name,
        zIndexOffset: this.isAvailable ? 1 : 0,
        icon: this.getIcon(),
      }); }

      this.leafletMarker.setIcon(this.getIcon());
      this.leafletMarker;
    }

    draw(map) {
      return this.leafletMarker.addTo(map != null ? map.leafletMap : undefined);
    }

    getIcon() {
      const availableIcon = L.icon({
        iconUrl: this.icon_spot_available,
        iconSize: [34, 38],
        iconAnchor: [17, 38],
      });

      const unavailableIcon = L.icon({
        iconUrl: this.icon_spot_unavailable,
        iconSize: [24, 28],
        iconAnchor: [12, 28],
      });

      if (this.isAvailable) { return availableIcon; } else { return unavailableIcon; }
    }
  };
  Marker.initClass();
  return Marker;
})();
