import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  connect() {
    this.element.classList.add("u-cursor--pointer", "pointer")
  }

  navigate() {
    const link = this.element.querySelector("a").href
    this.element.classList.add("active")
    Turbo.visit(link)
  }
}

