import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    if(this.containerTarget.classList.contains("open")) {
      this.containerTarget.show();
      this.lockScroll();
    }
  }

  disconnect() {
    this.unlockScroll()
  }

  close() {
    this.containerTarget.hide();
    this.unlockScroll()
  }

  open() {
    this.containerTarget.show();
    this.lockScroll();
  }

  lockScroll() {
    document.body.classList.add("modal-open")
  }

  unlockScroll() {
    document.body.classList.remove("modal-open")
  }
}
