import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  get form() {
    return this.element
  }

  start(event) {
    event.preventDefault();

    const button = event.target

    this.form.dataset.loading = true
    button.disabled = true

    this.form.requestSubmit();
  }
}
